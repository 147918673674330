
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { length, stage, form } from "@/core/data/genericData";
import { active } from "@/core/data/genericData";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "add-contact-modal",
  directives: { mask },
  props: {
    id: { required: true },
  },
  components: {},

  // onChange(){
  //   if(this.formData.company_select == '')
  //   {
  //     this.formData.branch_select = ""
  //     console.log("select")
  //   }
  // },

  setup(props) {
    var ids = ref(props.id);
    const formRef = ref<null | HTMLFormElement>(null);
    const addContactModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const store = useStore();
    const router = useRouter();
    const designation = ref([]);
    const active_list_data = active;
    const branch = ref([]);
    const company = ref([]);

    const OnClickCompany = async (compid) => {
      try {
        var values = { company_id: compid, page: 1, records_per_page: 10 };
        await store
          .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
          .then(({ data }) => {
            branch.value = data.result_list;
            console.log(branch.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    onMounted(async () => {
      await setDesignationdata(designation.value);
      await setCompanyData(company.value);
      await OnClickCompany(branch.value);
    });

    const getPincodeData = async () => {
      loadingPin.value = true;

      const db_data = { search_term: formData.value.pincode };

      await store
        .dispatch(Actions.CUST_GET_PINCODE, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data) {
            formData.value.city = data["city_name"];
            formData.value.state = data["state_name"];
            formData.value.country = "India";
            formData.value.city_id = data["city_id"];
            formData.value.state_id = data["state_id"];
            formData.value.pincode_id = data["pincode_id"];
            formData.value.country_id = "1";

            loadingPin.value = false;
          } else {
            formData.value.city = "";
            formData.value.state = "";
            formData.value.country = "";
            formData.value.city_id = "";
            formData.value.state_id = "";
            formData.value.pincode_id = "";
            formData.value.country_id = "";

            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid Pincode!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

    const setDesignationdata = async (data) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_DESIGNATION_CONTACT, db_data)
          .then(({ body }) => {
            designation.value = body.business_natures;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setCompanyData = async (data) => {
      const db_data = {};
      //loading.value = true;
      try {
        var values = { company_id: 0, page: 1, records_per_page: 10 };
        await store
          .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
          .then(({ data }) => {
            company.value = data.result_list;
            //loading.value = false;
            console.log(company.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const formData = ref({
      name: "",
      company_select: "",
      branch_select: "",
      designation_select: "",
      email_select: "",
      mobile_select: "",
      landline_select: "",
      address_select: "",
      address2_select: "",
      pincode: "",
      pincode_id: "",
      city: "",
      state: "",
      country: "",
      city_id: "",
      state_id: "",
      country_id: "",
      active: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Contact Name is required",
          trigger: "change",
          pattern: "^[a-zA-Z]+$",
        },
      ],
      company_select: [
        {
          required: true,
          message: "Company is required",
          trigger: "change",
        },
      ],
      branch_select: [
        {
          required: true,
          message: "Branch is required",
          trigger: "change",
        },
      ],
      // designation_select: [
      //   {
      //     required: true,
      //     message: "Designation is required",
      //     trigger: "change",
      //   }
      // ],
      // email_select: [
      //   {
      //     required: true,
      //     message: "Email Form is required",
      //     trigger: "change",
      //   }
      // ],
      // mobile_select: [
      //   {
      //     required: true,
      //     message: "Mobile No. is required",
      //     trigger: "change",
      //     min: 10,
      //     max: 10,
      //   }
      // ],
      // // landline_select: [
      // //   {
      // //     required: true,
      // //     message: "Landline No. is required",
      // //     trigger: "change",
      // //   }
      // // ],
      // address_select: [
      //   {
      //     required: true,
      //     message: "Address Line 1 is required",
      //     trigger: "click",
      //   }
      // ],
      //  pincode_select: [
      //   {
      //     required: true,
      //     message: "Pincode is required",
      //     trigger: "click",
      //   }
      // ],
    });

    const setContactData = async (data) => {
      const db_data = {
        contact_name: data.name,
        company: data.company_select,
        company_branch: data.branch_select,
        designation_id: Number(data.designation_select),
        email: data.email_select,
        mobile_no: data.mobile_select,
        landline_no: data.landline_select,
        address_line_1: data.address_select,
        address_line_2: data.address2_select,
        pincode_id: Number(data.pincode_id),
        city_id: Number(data.city_id),
        state_id: Number(data.state_id),
        country_id: Number(data.country_id),
        active: data.active,
        verified_yes_no: 0,
      };

      await store
        .dispatch(Actions.CUST_ADD_CONTACT, db_data)
        .then(({ data }) => {
          if (data.contact_id) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Contact has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addContactModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setContactData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addContactModalRef,
      setDesignationdata,
      setCompanyData,
      getPincodeData,
      OnClickCompany,
      active_list_data,
      designation,
      company,
      branch,
      loadingPin,
      ids,
    };
  },
});
